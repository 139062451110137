import {
    mapMutations
  } from 'vuex'
export const API_MIXINS = {
    methods: {
        ...mapMutations([
            'setAlert',
            'setAlertColor', 
            'setAlertText', 
            'setLoggedIn', 
            'setSetOpen2dDraws',
            'setSetOpen3dDraws',
            'setSetOpenLiveDraws',
            'setIsOnline',
            'setTrucks',
            'setTickets',
            'setCategories',
            'setResults',
            'setCustomertypes',
            'setBalance',
            'setUsers',
            'setAccount',
            'setOverlayLoading',
            'setAccountSummary',
            'setOverlayLoadingText',
            'setRequiredUpdate'
        ]),
        GET_ALL_ACCOUNTS(user_id,user_role){
            this.$http.post("account/get_players",{id: user_id, role: user_role}).then(response => {
                console.log(response.data)
               response.data.list != null?this.setUsers(response.data.list):this.setUsers([])
             }).catch(e => {
               console.log(e.data)
             })
         },
        ACTIVEGAMES(user_games){
            var games = []
            for(var c=0;c<user_games.length;c++) {
                if(user_games[c].comm ==1) {
                 games.push(user_games[c].game)
                }
            }
            return games
          },
        GAMENAME(gamename){
            if(gamename == '2D') {
                return '2D NATIONAL'
            } else if(gamename == '3D') {
                return '3D NATIONAL'
            }  else if(gamename == '4D') {
                return '4D NATIONAL'
            } else {
                return gamename
            }
        },
        NUMCOMB(array, size){
            var result = [];
            array.forEach(function iter(parts) {
                return function (v) {
                    var temp = parts.concat(v);
                    if (parts.includes(v)) {
                        return;
                    }
                    if (temp.length === size) {
                        result.push(temp);
                        return;
                    }
                    array.forEach(iter(temp));
                }
            }([]));
            return result.length;
        },
        ValidateBuy() {
            // this.$http.post("user/change_password", account).then(response => {
            //    this.GET_RESPONSE(response)
            //    }).catch(e => {
            //     console.log(e.data)
            // }) 
        },
        CHANGE_PASSWORD(account) {
            this.$http.post("user/change_password", account).then(response => {
               this.GET_RESPONSE(response)
               }).catch(e => {
                console.log(e.data)
            }) 
        },

        GET_ACCOUNT_INFO(_id){
           
               this.$http.post("user/details",{id: this.$IsNum(_id)}).then(response => {
                console.log(response.data)
                response.data.status?this.setAccount(response.data.account):this.setAccount({})
               }).catch(e => {
                 console.log(e.data)
               })
         },
        GET_RESULTS() {
            this.$http.get("report/get_results").then(response => {
                response.data.results != null ?this.setResults(response.data.results): this.setResults({})
                //console.log('get_results2')
                console.log(this.$store.state.results)
               }).catch(e => {
                  //console.log(e.data, 'test')
                if(e.data == undefined) {
                    this.VA_ALERT('error', "Your session has expired!")
                    this.setDrawer(false)
                    this.CLEAR_SESSION()
                    this.$router.push('/login')
                } 
            })
        },
        CHECK_BALANCE(account) {
            this.$http.post("wallet/balance", {account:account}).then(response => {
                response.data.account != null ?this.setBalance(response.data.account): this.setBalance({})
               }).catch(e => {
                console.log(e.data)
            }) 
        },
        IS_ODD(num){
            if((this.$IsNum(num)%2) != 0) {
                    return 'odd'
            }
            return 'even'
        },
        GET_OPEN_GAMES(gamename){
            console.log("GET_OPEN_GAMES")
            this.$http.post("draw/get_open_draws", {game: gamename, status: "per_game"}).then(response => {
                if(gamename == "3D" || gamename == "4D") {
                    response.data.draws != null ?this.setSetOpen3dDraws(response.data.draws): this.setSetOpen3dDraws([])
                } else {
                    response.data.draws != null ?this.setSetOpen2dDraws(response.data.draws): this.setSetOpen2dDraws([])
                }
                console.log(response.data.draws )
               }).catch(e => {
                console.log(e.data)
            })
        },
    
       STATUS_COLOR(status){
        switch(status) {
           case "Add Load":
             return "text-success"
           case "Cash Out":
             return "text-warning"
            case "Buy Ticket":
            return "text-info"
            case "Win":
                return "text-success"
           default:
             return ""
        }
     },
        validateItems(items){
            var arrItems = items.reduce((res,item)=>{
                    item.sold = 0
                    res.push(item)
                    return res
            }, [])
            return arrItems
        },
        GET_TICKETS(param) {
            this.$http.post("transaction/query", param).then(response => {
                response.data.transactions !=null?this.setTickets(response.data.transactions):this.setTickets([])
                console.log(response.data.transactions)
               }).catch(e => {
                console.log(e.data)
            })
        },
        ACCOUNT_SUMMARY(param){
            console.log(param)
            this.$http.post("report/transactions", param).then(response => {
                console.log(response.data)
                response.data !=null?this.setAccountSummary(response.data):this.setAccountSummary({})
               }).catch(e => {
                console.log(e.data)
            })
        },
        GET_RESPONSE(response){
            response.data.status?this.VA_ALERT('success', response.data.message):this.VA_ALERT('error', response.data.message)
        },
        VA_ALERT(color, message) {
                this.setAlertText(message)
                this.setAlertColor(color)
                this.setAlert(true)
        },
        SYSTEM_UPDATE() {
            this.setRequiredUpdate(false)
            this.$http.get("system_update").then(response => {
              if(response.data.status) {
                  if(!localStorage._kake_system_version) {
                    localStorage._kake_system_version = response.data.system.version
                  } else {
                      if(localStorage._kake_system_version!=response.data.system.version){
                            this.setRequiredUpdate(true)
                      }
                  }
              }
               }).catch(e => {
                console.log(e.data)
            })
        },
        APPLOADER(state, text=''){
            state?this.setOverlayLoadingText(text):this.setOverlayLoadingText('')
            this.setOverlayLoading(state)

        },
          NETWORK_ERROR(e){
            if(e.response == undefined) {
                    this.VA_ALERT('Error', "Server Unreachable!")
            } else {
                this.VA_ALERT('error', "No avaialable this time!")
            }
          },
          PING() {
            this.$http.post("ping", {}, {timeout: 2000}).then(() => {
                this.setIsOnline(true)
               }).catch(e => {
                this.setIsOnline(false)
                this.NETWORK_ERROR(e)
            })
        },
        CLEAR_SESSION() {
            delete localStorage.customers
            delete localStorage.user
            delete localStorage.device_token_save
        },
        DATA_SIZE(){
            var _lsTotal = 0,
            _xLen, _x;
            for (_x in localStorage) {
                if (!localStorage.hasOwnProperty(_x)) {
                    continue;
                }
                if(_x.substr(0, 50) == 'sales') {
                    _xLen = ((localStorage[_x].length + _x.length) * 2);
                    _lsTotal += _xLen;
                   // console.log(_x.substr(0, 50) + " = " + (_xLen / 1024).toFixed(2) + " KB")
                }
            }
            return (_lsTotal / 1024).toFixed(2)
        },
        VA_TIME(){
            //2020-05-12 01:24:51.272793+08
         return this.$moment().format() //2020-05-16 06:28:59
        }
        
    } ///eof methods
}